<template>
	<div class="notice_popup prj_itv pass">
		<div class="close">
			<img class="ml-0" src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<tec-summary tagNm="div" class="member" :value="data"/>
		<div class="tit">프로젝트 인터뷰 결과</div>
		<img src="/images/admin/pop_itv_pass.png" alt="합격">
		<div class="itv_date">
			<p>참여기간</p><br/>
			<SelectDate tagNm="div" classY="wd01" class="select-box" type="date" v-model="data.joinStartYyyymmdd" from="Y-3" :to="data.joinEndYyyymmdd || 'Y3'" rules="required" v-if="data.mberSeq">
				<template v-slot:after> 부터</template>
			</SelectDate>
			<SelectDate tagNm="div" classY="wd01" class="select-box" type="date" v-model="data.joinEndYyyymmdd" :from="data.joinStartYyyymmdd || 'Y-3'" to="Y3" rules="required" v-if="data.mberSeq">
				<template v-slot:after> 까지</template>
			</SelectDate>
		</div>
		
		<div class="itv_date joinAsg">
			<p>참여 소속사</p>
			<AutoComplete type="text" v-model="joinAsgNm" :list="corpList" listText="corpNm" @autoComplete="setCorpInfo"  title="참여 소속사" :rules="{'required':true}" :disabled="true">
				<template v-slot:autoComplete="autoComplete">{{autoComplete.item.corpNm}} / {{autoComplete.item.bizRegNo}}</template>
			</AutoComplete>
		</div>

		<div class="itv_date rank">
			<p>투입등급</p>
			<SelectComp tagName="div" class="select_rank" type="radio" title="투입등급" v-model="data.projectJoinTechGradeCd" cdId="PRO106" rules="required" v-if="data.mberSeq" :showError="false"/>
		</div>
		<div class="qs">위의 정보로 기술인재의 인터뷰 상태를 변경하시겠습니까?</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close', false)">취소</div>
			<div class="btn" @click="save">예</div>
		</div>
	</div>
</template>
<script>
import tecSummary from "@/components/highpro/TecSummary.vue";

export default {
	components: { tecSummary },
	props: { param:Object },
	data() {
		return {
			data: {},
			corpList : [],
			joinAsgSeq : '',
			joinAsgNm : '',
		};
	},
	mounted(){
		var projectSeq = this.param.projectSeq;
		var tecMberSeq = this.param.tecMberSeq;
		var interviewReqSeq = this.param.interviewReqSeq;
		this.$.httpPost('/api/prj/sco/getPrjMberInfo', {projectSeq, tecMberSeq, interviewReqSeq})
			.then(res => {
				this.data = res.data;
				this.data.asgcorpNm = this.param.asgcorpNm;
				this.corpSearch(res.data.choiceCorpList);	
				this.$emit('updated');
			}).catch(this.$.httpErrorCommon);

		
	},
	methods: {

		corpSearch(choiceCorpList) {
            var param = {
				corpSeqList : choiceCorpList.split(','),
				order : 'asc'
            }
			this.$.httpPost('/api/prj/getChoiceScoList', param)
				.then(res => {
					this.corpList = res.data.choiceCorpList;
					this.joinAsgSeq = this.corpList[0].corpSeq;
					this.joinAsgNm = this.corpList[0].corpNm;
				}).catch(this.$.httpErrorCommon);
		},

		setCorpInfo(corpInfo) {
			
			this.joinAsgSeq = corpInfo.corpSeq;
			this.joinAsgNm = corpInfo.corpNm;

		},
		
		save(){

			if(this.joinAsgSeq == null || this.joinAsgSeq == '') {
				alert("참여 소속사를 선택해주세요!");

			}else{



				var interviewReqSeq = this.data.interviewReqSeq;
				var tecMberSeq		= this.param.tecMberSeq;
				var joinStartYyyymmdd	= this.data.joinStartYyyymmdd;
				var joinEndYyyymmdd		= this.data.joinEndYyyymmdd;
				var projectJoinTechGradeCd = this.data.projectJoinTechGradeCd;
				var joinAsgSeq = this.joinAsgSeq;

				this.$.httpPost('/api/prj/sco/saveInterviewPass', {interviewReqSeq, tecMberSeq, joinStartYyyymmdd, joinEndYyyymmdd, projectJoinTechGradeCd, joinAsgSeq})
				.then(() => {
					alert('합격 처리가 완료되었습니다.')
					this.$emit('close', true);
				}).catch(this.$.httpErrorCommon);
			}

		
			
		}
	},
};
</script>
